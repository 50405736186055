import React, { useContext, useState, Fragment } from 'react';
import context from '../context';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Link from './link';

import { Section, Button } from '../styled-components';

const SectionCustom = styled(Section)` 
  border: 1px solid #D8D8D8;
  border-left: none;
  border-right: none;
  padding: 2rem 0;
`
const Title = styled.p`
  margin: 0;
  margin-bottom: 1rem;
  @media(min-width:768px){
    margin-bottom: 0;
  }
`
const ContactCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 6rem;
`
const ContactTitle = styled.h3`

`
const ContactSubTitle = styled.p`
  font-size: 1.2rem;
  margin: 1.5rem 0;
`

export default ()=> {
  const state = useContext(context);
  return(
        <ContactCont>
          <ContactTitle>
            ¿Tienes alguna duda?
          </ContactTitle>
          <ContactSubTitle>
            Llámanos o escríbenos, un agente estará encantado de asistir tu proceso.
          </ContactSubTitle>
          <Link to="/contact">
            <Button primary>
              Contacto
            </Button>
          </Link>
        </ContactCont>
  )
}